import React, {Component} from 'react';
import {Container} from 'react-bootstrap'
import Header from '../components/header'
import Breadcrumb from '../components/breadcrumb'
import Footer from '../components/footer'
import Seo from '../components/seo'
import pathspliter from '../functions/pathspliter'
import Inparser from '../functions/inparser'
// import {Link} from 'gatsby'
import {graphql} from 'gatsby'

class Prices extends Component {

  render() {

    // var inbody = this.props.data.drupal.mainprices.entities[0].body.processed

    return (<div>
      <Seo canonical={`${this.props.pageContext.baseurl}${this.props.path}`} metainfo={this.props.data.drupal.metainfo.entities[0].entityMetatags} />
      <Header contactinfo={this.props.pageContext.contactinfo.entities[0]} langcode={this.props.pageContext.langcode} langcontainer={this.props.pageContext.langcontainer} path={this.props.path} menu={this.props.pageContext.menu}/>
      <Breadcrumb path={pathspliter(this.props.path)} title={this.props.pageContext.title}/>
       <main>
              <Container>
                <section className="py-6">

           <Inparser
                body={this.props.data.drupal.mainprices.entities[0].body}
                baseurl={this.props.pageContext.baseurl}
                fileQuery={this.props.data.drupal.fileQuery.entities}

              />
           </section>
           </Container>
           </main>
      <Footer social={this.props.pageContext.social} langcode={this.props.pageContext.langcode}/>
    </div>);
  }

}

export default Prices;

export const query = graphql`
  query($langcode: DRUPAL_LanguageId, $parsed:[DRUPAL_EntityQueryFilterConditionInput]) {
    drupal {

      mainprices: nodeQuery(filter: {conditions: [{field: "type", value: "page", language:$langcode}]}) {
        entities(language:$langcode) {

            ... on DRUPAL_NodePage {

              title

              entityLanguage{
                id
              }

              entityUrl {
                path
              }
              body{
                processed
              }
            }

        }
      }

      fileQuery(limit: 300, filter: {conditions: $parsed, conjunction: OR}) {
  count
  entities {
    ... on DRUPAL_File {
      url
      imageFile {
              id
             publicURL
             name
             }
    }
  }
}

      metainfo:nodeQuery(filter: {conditions: [{field: "type", value: "page"}]}) {
  entities(language:$langcode) {
    ... on DRUPAL_NodePage {

      entityMetatags {
        value
        key
      }

    }
  }
}











}
  }
`
