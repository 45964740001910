//For Drupal use only
//Parser COmponent for react
//Use instead of dangerouslySetInnerHTML
//Use if body.processed contains images
//Takes 3 props 1.body 2.baseurl 3.the imagelist fileQuery filtered by graphql


import React from 'react'
import ReactHtmlParser from 'react-html-parser';

class Inparser extends React.Component {
  render() {
    return (

      ReactHtmlParser(this.props.body.processed, {
       transform: (node)=> {



         if(node.name === 'img'){

               return (
                 this.props.fileQuery.filter((item)=> {
                   return item.url === `${this.props.baseurl}${node.attribs.src}`
                 }).map((item) => {
                   return (
                     <img key={item.imageFile.id} src={item.imageFile.publicURL} alt={item.imageFile.name}/>
                   )
                 })

               )





         }


       }

     })

    )
  }
}

export default Inparser
